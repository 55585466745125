<template>
  <div v-frag>
    <v-row class='d-flex flex-row align-center justify-center px-0 my-5 ma-5'>
      <v-alert
        v-model='rejectedAlert'
        class='rounded-xl'
        color='grey'
        dark
        dense
        dismissible
        icon='mdi-information-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>Request Rejected</h3>
      </v-alert>
      <v-alert
        v-model='approvedAlert'
        class='rounded-xl'
        color='success'
        dark
        dense
        dismissible
        icon='mdi-information-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>Request Acknowledged</h3>
      </v-alert>
      <v-card class='rounded-lg d-flex flex-column' elevation='3' width='100%'>
        <v-data-table
          v-model='selected'
          :headers='pendingRequestsHeaders'
          :items='pendingRequests'
          :loading='loadingRequests'
          :search='searchPending'
          class='mx-6'
          item-key='id'
          show-select
        >
          <template v-slot:top>
            <v-toolbar class='mb-16' flat>
              <v-col>
                <v-toolbar-title class='font-weight-bold headline mt-16'>
                  {{ sections.pending_requests || 'Pending Requests title' }}
                </v-toolbar-title>
                <p
                  v-show='pendingRequests.length > 0'
                  class='font-weight-bold mt-6'
                >
                  You have
                  <span class='error--text'>{{ pendingRequests.length }}</span>
                  pending requests.
                </p>

                <p
                  v-show='pendingRequests.length == 0'
                  class='mt-6 font-weight-bold success--text'
                >
                  You don't have any pending requests. Congratulations!
                  <v-icon color='success'>mdi-check</v-icon>
                </p>
              </v-col>
              <v-col>
                <v-text-field
                  v-model='searchPending'
                  class='mr-4'
                  dense
                  hide-details
                  label='Search'
                  outlined
                  prepend-inner-icon='mdi-magnify'
                  single-line
                >
                </v-text-field>
              </v-col>
            </v-toolbar>
            <v-row v-show='selected.length > 1' class='mb-6 ml-6'>
              <v-btn
                class='mr-2 text-capitalize font-weight-bold'
                color='success'
                @click='openApprovalDialog()'
              >
                <v-icon class='mr-2'> mdi-check</v-icon>
                Acknowledge All
              </v-btn>
              <v-btn
                class='text-capitalize font-weight-bold'
                color='error'
                @click='openRejectRequestDialog'
              >
                <v-icon class='mr-2'> mdi-close-thick</v-icon>
                Reject All
              </v-btn>
            </v-row>
          </template>
          <template #item.charity={item}>
            <span class='charityName' @click='redirect(item)'>{{ item.charity }}</span>
          </template>
          <template #item.actions={item}>
            <span class='d-flex flex-row'>
              <v-col>
                <v-btn
                  class='text-capitalize mr-2'
                  color='success'
                  small
                  @click='openApprovalDialog(item)'
                >Acknowledgment
                </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class='text-capitalize'
                color='error'
                small
                @click='openRejectRequestDialog(item)'
              >Reject
              </v-btn>
            </v-col>
            </span>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <v-row class='d-flex flex-row align-center justify-center px-0 ma-5'>
      <v-expansion-panels class='elevation-3 rounded-lg'>
        <v-expansion-panel class='rounded-lg'>
          <v-expansion-panel-header class='font-weight-bold headline pl-13'>
            {{ sections.requests_history || 'Requests History title' }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers='requestHistoryHeaders'
              :items='requestHistory'
              :loading='loadingHistory'
              :search='searchHistory'
              class='mx-6'
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-col>
                    <v-text-field
                      v-model='searchHistory'
                      class='mr-4'
                      dense
                      hide-details
                      label='Search'
                      outlined
                      prepend-inner-icon='mdi-magnify'
                      single-line
                    >
                    </v-text-field>
                  </v-col>
                </v-toolbar>
              </template>
              <template v-slot:[`item.state`]='{ item }'>
            <span
              v-if="item.state === 'Acknowledged'"
              class='font-weight-bold success--text'
            >{{ item.state }}</span
            >
                <span
                  v-if="item.state === 'Rejected'"
                  class='font-weight-bold error--text'
                >{{ item.state }}</span
                >
                <span
                  v-if="item.state === 'Pending'"
                  class='font-weight-bold warning--text'
                >{{ item.state }}</span
                >
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model='showRejectRequestDialog' max-width='600px' persistent>
      <v-card class='py-2 px-6'>
        <v-card-title class='text-left mb-4 ml-n6'>
          <v-icon class='error--text mr-4' large>mdi-alert</v-icon>
          Reject Day Off Request
        </v-card-title>
        <v-card-subtitle class='text-left ml-n6 font-weight-bold'
        >Message
        </v-card-subtitle
        >
        <v-row>
          <v-textarea
            v-model='editedItem.message'
            outlined
            width='100%'
          ></v-textarea>
        </v-row>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold white--text text-capitalize'
            color='primary'
            large
            outlined
            @click='closeDialog()'
          >
            Cancel
          </v-btn
          >
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text text-capitalize'
            color='error'
            large
            @click='rejectRequests()'
          >
            Reject
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='showApproveRequestDialog' max-width='600px' persistent>
      <v-card class='py-2 px-6'>
        <v-card-title class='text-left mb-4 ml-n6'>
          <v-icon class='mr-4 white--text' color='success' large left
          >mdi-alert
          </v-icon
          >
          Acknowledge Request
        </v-card-title>
        <v-card-subtitle class='text-left font-weight-bold py-8'
        >Are you sure you want to acknowledge this
         request?
        </v-card-subtitle
        >
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold white--text text-capitalize'
            color='primary'
            large
            outlined
            @click='showApproveRequestDialog = false'
          >
            Cancel
          </v-btn
          >
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text text-capitalize'
            color='primary'
            large
            @click='approveRequests()'
          >
            Acknowledge
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import member from '../../services/member/index'
import employee from '../../services/employee/index'
import {mapState} from 'vuex'

export default {
  data: () => ({
    memberLogged: false,
    employeeLogged: false,
    disableApprove: false,
    loading: false,
    selected: [],
    approvalItem: {},
    editedItem: {},
    valid: true,
    rejectedAlert: false,
    approvedAlert: false,
    loadingRequests: false,
    loadingHistory: false,
    searchPending: '',
    searchHistory: '',
    pendingRequests: [],
    requestHistory: [],
    showRejectRequestDialog: false,
    showApproveRequestDialog: false,
    pendingRequestsHeaders: [
      {text: 'Name', align: 'start', sortable: true, value: 'name', width: '150px'},
      {text: 'Hierarchy', value: 'hierarchy', width: '100px'},
      {text: 'Reports To', value: 'reportsTo', width: '150px'},
      {text: 'Date', value: 'date', width: '100px'},
      {text: 'Type', value: 'type', width: '100px'},
      {text: 'Opportunity', value: 'opportunity', width: '100px'},
      {text: 'Charity', value: 'charity', width: '100px'},
      {text: 'Hours', value: 'hours', width: '50px'},
      {text: 'Actions', value: 'actions', align: 'center', width: '200px'}
    ],
    requestHistoryHeaders: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {text: 'Hierarchy', value: 'hierarchy'},

      {text: 'Acknowledged By', value: 'approvedBy'},
      // { text: "Leave", value: "leave" },
      {text: 'Date', value: 'date'},
      {text: 'Hours', value: 'hours'},
      {text: 'Type', value: 'type'},
      {text: 'Status', value: 'state'}
    ]
    // messageRules: [
    //   (v) => !!v || "Message is required",
    //   (v) =>
    //     (v && v.length > 30) || "Message must be at least 30 characters long",
    // ],
  }),
  created() {
    if (this.$store.getters.user.profile_id.employee_id) {
      this.employeeLogged = true
    }
    if (
      this.$store.getters.user.profile_id.member_id &&
      !this.$store.getters.user.profile_id.employee_id
    ) {
      this.memberLogged = true
    }
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.memberLogged) {
        this.memberGetPendingRequests()
        this.memberGetHistoricalRequests()
      }
      if (this.employeeLogged) {
        this.employeeGetPendingRequests()
        this.employeeGetHistoricalRequests()
      }
    },
    redirect(item) {
      this.$store.dispatch('charity', item.charityId)
      this.$router.push(`/member/charity-donations/${item.charityId}`)
    },
    openRejectRequestDialog(item) {
      this.showRejectRequestDialog = true
      this.editedItem = item
      this.editedItem.message = ''
    },
    closeDialog() {
      this.editedItem = {}
      this.approvalItem = {}
      this.selected = []
      this.showRejectRequestDialog = false
      this.showApproveRequestDialog = false
    },
    async rejectRequests() {
      this.loading = true
      const message = this.editedItem.message
      const reqArray = []

      if (this.selected.length > 1) {
        this.selected.forEach((req) => {
          reqArray.push({id: req.id, deniedReason: message, type: req.type})
          let index = this.pendingRequests.indexOf(req)
          this.pendingRequests.splice(index, 1)
        })
        if (this.memberLogged) {
          await this.memberRejectRequests(reqArray)
        }
        if (this.employeeLogged) {
          await this.employeeRejectRequests(reqArray)
        }
        this.closeDialog()
        this.showAlertMsg('rejected')
        this.loading = false
      }
      if (this.selected < 1) {
        reqArray.push({id: this.editedItem.id, deniedReason: message, type: this.editedItem.type})
        let index = this.pendingRequests.indexOf(this.editedItem)
        this.pendingRequests.splice(index, 1)
        if (this.memberLogged) {
          await this.memberRejectRequests(reqArray)
        }
        if (this.employeeLogged) {
          await this.employeeRejectRequests(reqArray)
        }
        this.closeDialog()
        this.showAlertMsg('rejected')
        this.loading = false
      }
      this.selected = []
    },
    openApprovalDialog(item) {
      this.showApproveRequestDialog = true
      this.approvalItem = item
    },
    approveRequests() {
      this.disableApprove = true
      this.loading = true

      if (this.approvalItem) {
        this.approveSingleRequest(this.approvalItem)
      } else {
        this.approveMultipleRequests()
      }
    },
    async approveSingleRequest(item) {
      const idsArray = []
      idsArray.push({id: item.id, type: item.type})
      let index = this.pendingRequests.indexOf(item)
      this.pendingRequests.splice(index, 1)

      if (this.memberLogged) {
        await this.memberApproveRequest(idsArray)
      }
      if (this.employeeLogged) {
        await this.employeeApproveRequest(idsArray)
      }
      this.approvalItem = {}
    },
    async approveMultipleRequests() {
      const idsArray = []
      this.selected.forEach((request) => {
        idsArray.push({id: request.id, type: request.type})

        let index = this.pendingRequests.indexOf(request)
        this.pendingRequests.splice(index, 1)
      })

      if (this.memberLogged) {
        await this.memberApproveRequest(idsArray)
      }
      if (this.employeeLogged) {
        await this.employeeApproveRequest(idsArray)
      }
      this.selected = []
    },

    // MEMBER LOGGED OPERATIONS

    async memberGetPendingRequests() {
      const memberId = this.$store.getters.user.profile_id.member_id
      try {
        this.pendingRequests = await member.pendingRequests({id: memberId})
      } catch (err) {
        console.log(err)
      }
    },
    async memberGetHistoricalRequests() {
      const memberId = this.$store.getters.user.profile_id.member_id
      try {
        this.requestHistory = await member.historicalRequests({id: memberId})
        this.requestHistory.sort((a, b) => new Date(b.date) - new Date(a.date))
      } catch (err) {
        console.log(err)
      }
    },
    async memberApproveRequest(idsArray) {
      try {
        const memberId = this.$store.getters.user.id
        await member.approveRequests({requestIds: idsArray, id: memberId})
        this.showApproveRequestDialog = false
        this.loading = false
        this.disableApprove = false
        this.showAlertMsg('approved')
        await this.memberGetHistoricalRequests()
      } catch (err) {
        console.log(err)
      }
    },
    async memberRejectRequests(idsArray) {
      try {
        const memberId = this.$store.getters.user.id
        await member.rejectRequests({requestIds: idsArray, id: memberId})
        await this.memberGetHistoricalRequests()
      } catch (err) {
        console.log(err)
      }
    },

    // EMPLOYEE LOGGED OPERATIONS

    async employeeGetPendingRequests() {
      const employeeId = this.$store.getters.user.profile_id.employee_id
      try {
        this.pendingRequests = await employee.pendingRequests({
          id: employeeId
        })
      } catch (err) {
        console.log(err)
      }
    },
    async employeeGetHistoricalRequests() {
      const employeeId = this.$store.getters.user.profile_id.employee_id
      try {
        this.requestHistory = await employee.historicalRequests({
          id: employeeId
        })
      } catch (err) {
        console.log(err)
      }
    },
    async employeeApproveRequest(idsArray) {
      const employeeId = this.$store.getters.user.id
      try {
        await employee.approveRequests({
          requestIds: idsArray,
          employeeId: employeeId
        })
        this.showApproveRequestDialog = false
        this.loading = false
        this.disableApprove = false
        this.showAlertMsg('approved')
        this.employeeGetHistoricalRequests()
      } catch (err) {
        console.log(err)
      }
    },
    async employeeRejectRequests(idsArray) {
      const employeeId = this.$store.getters.user.id
      try {
        await employee.rejectRequests({
          requestIds: idsArray,
          employeeId: employeeId
        })
        this.employeeGetHistoricalRequests()
      } catch (err) {
        console.log(err)
      }
    },

    // ALERTS

    showAlertMsg(type) {
      switch (type) {
        case 'approved':
          this.approvedAlert = true
          break
        case 'rejected':
          this.rejectedAlert = true
          break
      }
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.approvedAlert = false
        this.rejectedAlert = false
      }, 5000)
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections']),
  }
}
</script>
<style lang='scss'>
.absolute {
  position: absolute;
}
</style>
